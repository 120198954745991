'use client';
import { Button } from '@/components/ui/button';
import useMixPanel from '@/hooks/useMixPanel';
import Link from 'next/link';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeHome = () => {
  const MixPanel = useMixPanel();

  useEffect(() => {
    return MixPanel.track('Welcome Home Screen Viewed', {
      screen: 'Welcome Home'
    });
  }, []);

  const { t } = useTranslation('home');
  return (
    <div className="container text-center h-[75vh] flex flex-col justify-center">
      <h1 className="text-3xl font-bold mb-3">{t('title')}</h1>
      <p>{t('description')}</p>
      <Button asChild className="mt-12">
        <Link
          onClick={() =>
            MixPanel.track('press get started button', {
              screen: 'Welcome Home'
            })
          }
          href="/bookings"
        >
          {t('button')}
        </Link>
      </Button>
    </div>
  );
};

export default WelcomeHome;
